import { createAction } from "redux-actions";

const prefixed = (val) => `Patients::${val}`;

export const requestResults = createAction(
  prefixed("REQUEST_RESULTS"),
  (
    groupType,
    groupValue,
    filter,
    keyword,
    sortBy,
    sortDirection,
    page,
    legacy
  ) => ({
    groupType,
    groupValue,
    filter,
    keyword,
    sortBy,
    sortDirection,
    page,
    legacy,
  })
);
export const didFetchResults = createAction(prefixed("DID_FETCH_RESULTS"));

export const requestExternalFields = createAction(
  prefixed("REQUEST_EXTERNAL_SERVICE_FIELDS")
);
export const didFetchExternalFields = createAction(
  prefixed("DID_FETCH_EXTERNAL_SERVICE_FIELDS"),
  (fields) => fields
);

export const requestGroups = createAction(
  prefixed("REQUEST_GROUPS"),
  (legacy) => legacy
);
export const didFetchGroups = createAction(prefixed("DID_FETCH_GROUPS"));

export const requestFilters = createAction(
  prefixed("REQUEST_FILTERS"),
  (groupType, groupValue, legacy) => ({ groupType, groupValue, legacy })
);
export const didFetchFilters = createAction(
  prefixed("DID_FETCH_FILTERS"),
  (filters) => filters
);

export const requestInvite = createAction(prefixed("REQUEST_INVITE"));
export const didFetchInvite = createAction(prefixed("DID_FETCH_INVITE"));

export const requestInviteAdmin = createAction(
  prefixed("REQUEST_INVITE_AS_ADMIN")
);
export const didFetchInviteAdmin = createAction(
  prefixed("DID_FETCH_INVITE_AS_ADMIN")
);

export const resetInviteAdmin = createAction(prefixed("RESET_INVITE_AS_ADMIN"));

export const requestSendInvite = createAction(prefixed("REQUEST_SEND_INVITE"));
export const didSendInvite = createAction(prefixed("DID_SEND_INVITE"));

export const requestCustomers = createAction(prefixed("REQUEST_CUSTOMERS"));
export const didFetchCustomers = createAction(prefixed("DID_FETCH_CUSTOMERS"));

export const requestPartnerUsers = createAction(
  prefixed("REQUEST_PARTNER_USERS")
);
export const requestPartnerUsersAdmin = createAction(
  prefixed("REQUEST_PARTNER_USERS_AS_ADMIN")
);
export const didFetchPartnerUsers = createAction(
  prefixed("DID_FETCH_PARTNER_USERS")
);

export const setLegacySearch = createAction(
  prefixed("SET_LEGACY_SEARCH"),
  (flag) => flag
);
