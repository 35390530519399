import { connect } from "react-redux";
import KeywordSearch from "./KeywordSearch.jsx";
import { setLegacySearch } from "../../actions";
import { shouldLegacySearch } from "../../reducer";

function mapDispatchToProps(dispatch, ownProps) {
  return {
    ...ownProps,
    setLegacySearch: (v) => dispatch(setLegacySearch(v)),
  };
}

function mapStateToProps(state) {
  return {
    legacySearch: shouldLegacySearch(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeywordSearch);
