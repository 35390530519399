import React from "react";
import classNames from "classnames";

class KeywordSearch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: "",
    };

    this.searchFieldRef = null;

    this.searchWithKeyword = this.searchWithKeyword.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeywordSearchTabClick =
      this.handleKeywordSearchTabClick.bind(this);
  }

  searchWithKeyword(keyword) {
    const { activateKeywordSearch } = this.props;

    this.setState({ searchTerm: "" });
    activateKeywordSearch(keyword);
  }

  handleKeyPress(e) {
    const { searchTerm } = this.state;

    if (e.key === "Enter") this.searchWithKeyword(searchTerm);
  }

  handleKeywordSearchTabClick() {
    const { keywordSearchActive, lastKeywordSearch } = this.props;

    if (!keywordSearchActive) {
      this.searchFieldRef.focus();
      this.searchWithKeyword(lastKeywordSearch);
    }
  }

  renderKeywordSearchTab() {
    const { userType, keywordSearchActive } = this.props;

    const groupClassName = classNames("group-filter", {
      selectedGroupFilter: keywordSearchActive,
    });
    const filterClassName = classNames("filter", {
      selectedFilter: keywordSearchActive,
    });

    if (["admin", "partner_admin"].includes(userType)) {
      return (
        <li
          key="Search Results Filter"
          role="button"
          className={groupClassName}
          onClick={this.handleKeywordSearchTabClick}
        >
          <span className="group-filter-label">Search Results</span>
        </li>
      );
    }
    return (
      <div
        key="Search Results Filter"
        role="button"
        className={filterClassName}
        onClick={this.handleKeywordSearchTabClick}
      >
        <div className="filter-text-group">
          <span className="filter-label">Search Results</span>
        </div>
      </div>
    );
  }

  renderKeywordSearchField() {
    const { lastKeywordSearch, userType } = this.props;
    const { searchTerm } = this.state;

    const searchInputClassName = classNames("search-field-input", {
      asNonAdmin: ["owner", "care_team_user"].includes(userType),
    });

    return (
      <div key="search-input" className="search-field-container">
        <input
          className={searchInputClassName}
          placeholder="Search name, claim, email, employer"
          ref={(node) => {
            this.searchFieldRef = node;
          }}
          onChange={(e) => this.setState({ searchTerm: e.target.value })}
          onKeyPress={this.handleKeyPress}
          value={searchTerm}
        />
        {searchTerm.length > 0 && (
          <button
            className="search-field-button"
            disabled={
              lastKeywordSearch.toLowerCase() ===
              searchTerm.toLowerCase().trim()
            }
            onClick={() => this.searchWithKeyword(searchTerm)}
          >
            Search
          </button>
        )}
      </div>
    );
  }

  render() {
    return (
      <div style={{ display: "flex" }}>
        {this.renderKeywordSearchTab()}
        {this.renderKeywordSearchField()}
        {this.renderLegacy()}
      </div>
    );
  }

  renderLegacy() {
    const { setLegacySearch, legacySearch } = this.props;

    return (
      <div style={{ marginLeft: "10px", marginTop: "-7px" }}>
        <input
          style={{ marginRight: "10px" }}
          type="checkbox"
          checked={legacySearch}
          onChange={() => setLegacySearch(!legacySearch)}
        />
        <span
          style={{
            display: "inline-block",
            verticalAlign: "top",
            marginTop: "14px",
          }}
        >
          Legacy Search
        </span>
      </div>
    );
  }
}

export default KeywordSearch;
