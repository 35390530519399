import axios from "axios";

import { objectToQuery } from "../../shared/routes/query";

export const fetchPatientResults = ({
  groupType,
  groupValue,
  filter = [],
  keyword = "",
  sortBy = "created_at",
  sortDirection = "desc",
  page = 0,
  perPage = 30,
  legacy = false,
}) => {
  const query = objectToQuery({
    group_type: groupType,
    group_value: groupValue,
    events: encodeURIComponent(filter),
    keyword: encodeURIComponent(keyword),
    sort_by: sortBy,
    sort_direction: sortDirection,
    page: page && parseInt(page, 10),
    per_page: perPage && parseInt(perPage, 10),
    legacy,
  });
  return axios.get(`api/admin/v1/search/?${query}`);
};

export const fetchFilters = ({
  groupType = "",
  groupValue = "",
  legacy = false,
}) => {
  const query = objectToQuery({
    group_type: groupType,
    group_value: groupValue,
    legacy,
  });
  return axios.get(`/api/admin/v1/search/filters/?${query}`);
};

export const fetchGroups = (legacy = false) =>
  axios.get(`/api/admin/v1/search/groups?legacy=${legacy}`);
export const fetchExternalFields = () =>
  axios.get("/api/admin/v1/users/external_service_fields");
export const fetchAllPartnerUsers = (customerId) =>
  axios.get(`/api/admin/v1/partner_users/?customer_id=${customerId}`);
export const fetchInvite = () => axios.get("/api/admin/v1/invite/");
export const fetchInviteAdmin = (clinicianId) =>
  axios.get(`/api/admin/v1/invite?clinician_id=${clinicianId}&demo=false`);
export const postInvite = (values) =>
  axios.post("/api/admin/v1/invite/", values);
export const fetchCustomers = () => axios.get("/api/admin/v1/customers/");
export const fetchCareTeamMember = (email) =>
  axios.get(
    `/api/admin/v1/care_team_users?page=0&keyword=${encodeURIComponent(email)}`
  );
export const createCareTeamMember = ({ email, job }) =>
  axios.post("/api/admin/v1/care_team_users/", { email, job });
